import ListIcon from '@mui/icons-material/List';
import { AccessControl } from 'common/api/multimap';
import { MappingSets as AdminMappingSets } from 'features/admin/mapping-sets/MappingSets';
import { Detail as AdminMappingSetDetail } from 'features/admin/mapping-sets/detail/Detail';
import { Learning as AdminMappingSetDetailLearning } from 'features/admin/mapping-sets/detail/Learning';
import { Modules as AdminModules } from 'features/admin/modules/Modules';
import { ConditionDescriptions as AdminModuleConditionDescriptions } from 'features/admin/modules/detail/ConditionDescriptions';
import { Reports as AdminReports } from 'features/admin/reports/Reports';
import { Detail as AdminModuleDetail } from 'features/admin/modules/detail/Detail';
import { Weighting as AdminModuleDetailWeighting } from 'features/admin/modules/detail/Weighting';
import { Properties as AdminProperties } from 'features/admin/object-properties/ObjectProperties';
import { ObjectTypes as AdminObjectTypes } from 'features/admin/object-types/ObjectTypes';
import { Detail as AdminObjectTypeDetail } from 'features/admin/object-types/detail/Detail';
import { Organizations } from 'features/admin/organizations/Organizations';
import { MyOrganizationUserDetail } from 'features/admin/organizations/my-organization-user-detail/MyOrganizationUserDetail';
import { MyOrganizationUsers } from 'features/admin/organizations/my-organization-users/MyOrganizationUsers';
import { MyOrganization } from 'features/admin/organizations/my-organization/MyOrganization';
import { OrganizationDetail } from 'features/admin/organizations/organization-detail/OrganizationDetail';
import { OrganizationModules } from 'features/admin/organizations/organization-modules';
import { OrganizationServiceTypes } from 'features/admin/organizations/organization-service-types';
import { OrganizationUserDetail } from 'features/admin/organizations/organization-user-detail/OrganizationUserDetail';
import { OrganizationUsers } from 'features/admin/organizations/organization-users/OrganizationUsers';
import { ActiveUsers } from 'features/admin/organizations/organization-users/active-users';
import { InvitedUsers } from 'features/admin/organizations/organization-users/invited-users';
import { OrganizationWorkspaces } from 'features/admin/organizations/organization-workspaces';
import { UserInvitations } from 'features/admin/organizations/user-invitations/UserInvitations';
import { Requests as AdminRequests } from 'features/admin/requests';
import { Detail as AdminRequestsDetail } from 'features/admin/requests/detail';
import { Workspaces as AdminWorkspaces } from 'features/admin/workspaces/Workspaces';
import { CreateWizard as AdminWorkspaceCreateWizard } from 'features/admin/workspaces/create/CreateWizard';
import { Detail as AdminWorkspaceDetail } from 'features/admin/workspaces/detail/Detail';

import { RouteItem } from './RouteItem';
import { RouteItemGroup } from './RouteItemGroup';
import { Users } from 'features/admin/users/Users';

export const adminRouteGroups: RouteItemGroup[] = [
  {
    label: 'adminSidebar.customers.title',
    children: [
      {
        component: <AdminRequests />,
        label: 'adminSidebar.customers.requests',
        hidden: false,
        requiredPermission: AccessControl.CustomerAccessRequestListRead,
        path: 'requests',
        icon: <ListIcon />,
      },
      {
        component: <AdminRequestsDetail />,
        hidden: true,
        requiredPermission: AccessControl.CustomerAccessRequestEdit,
        path: 'requests/:id/detail',
        icon: <ListIcon />,
      },
      {
        component: <Organizations />,
        label: 'adminSidebar.customers.organizations',
        hidden: false,
        requiredPermission: AccessControl.OrganizationListRead,
        path: 'organizations',
        icon: <ListIcon />,
      },
      {
        component: <OrganizationDetail />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId',
        icon: <ListIcon />,
      },
      {
        component: <OrganizationModules />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId/modules',
        icon: <ListIcon />,
      },
      {
        component: <ActiveUsers />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId/users/active',
        icon: <ListIcon />,
      },
      {
        component: <InvitedUsers />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId/users/invited',
        icon: <ListIcon />,
      },
      {
        component: <OrganizationWorkspaces />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId/workspaces',
        icon: <ListIcon />,
      },
      {
        component: <OrganizationServiceTypes />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'organizations/:organizationId/service-types',
        icon: <ListIcon />,
      },
      {
        component: <Users />,
        label: 'adminSidebar.customers.users',
        hidden: false,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'users',
        icon: <ListIcon />,
      }
    ],
  },
  {
    label: 'adminSidebar.administration.title',
    children: [
      {
        component: <AdminObjectTypes />,
        label: 'adminSidebar.administration.objectTypes',
        hidden: false,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'object-types',
        icon: <ListIcon />,
      },
      {
        component: <AdminObjectTypeDetail />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'object-types/:id/detail',
        icon: <ListIcon />,
      },
      {
        component: <AdminObjectTypeDetail />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'object-types/detail',
        icon: <ListIcon />,
      },
      {
        component: <AdminProperties />,
        label: 'adminSidebar.administration.properties',
        hidden: false,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'object-properties',
        icon: <ListIcon />,
      },
      {
        component: <AdminMappingSets />,
        label: 'adminSidebar.administration.parameterSet',
        hidden: false,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'mapping-sets',
        icon: <ListIcon />,
      },
      {
        component: <AdminMappingSetDetail />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'mapping-sets/:id/detail',
        icon: <ListIcon />,
      },
      {
        component: <AdminMappingSetDetailLearning />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'mapping-sets/:id/detail/learning',
        icon: <ListIcon />,
      },
      {
        component: <AdminMappingSetDetail />,
        hidden: true,
        requiredPermission: AccessControl.AdminDashboardView,
        path: 'mapping-sets/detail',
        icon: <ListIcon />,
      },
      {
        component: <AdminWorkspaces />,
        hidden: false,
        path: 'workspaces',
        requiredPermission: AccessControl.AdminDashboardView,
        label: 'adminSidebar.administration.workspaces',
        icon: <ListIcon />,
      },
      {
        component: <AdminWorkspaceDetail />,
        hidden: true,
        path: 'workspaces/:id/types/:typeid/detail',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminWorkspaceDetail />,
        hidden: true,
        path: 'workspaces/types/:typeid/detail',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminWorkspaceCreateWizard admin={true} />,
        hidden: true,
        path: 'workspaces/detail',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminModules />,
        label: 'adminSidebar.administration.modules',
        hidden: false,
        path: 'modules',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminModuleDetail />,
        hidden: true,
        path: 'modules/:id/detail',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminModuleDetailWeighting />,
        hidden: true,
        path: 'modules/:id/detail/weighting',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminModuleDetail />,
        hidden: true,
        path: 'modules/detail',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminModuleConditionDescriptions />,
        hidden: true,
        path: 'modules/:id/detail/condition-descriptions',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      },
      {
        component: <AdminReports />,
        label: 'adminSidebar.administration.reports',
        hidden: false,
        path: 'reports',
        requiredPermission: AccessControl.AdminDashboardView,
        icon: <ListIcon />,
      }
    ],
  },
];

export const adminRoutes: RouteItem[] = adminRouteGroups.map((x) => x.children).reduce((x, y) => x.concat(y), []);
