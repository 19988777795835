import { AxiosResponse, AxiosResponseHeaders } from 'axios';

/** Decode a encodded filename */
export const urlDecode = (uri: string) => decodeURIComponent(uri.replace(/\+/g, ' '));

/** Extract a filename form the content-disposition header. */
export const getFilename = (header: string): string | null => {
  // const filename = header.split(';')[1].split('filename')[1].split('=')[1].trim();
  const matches = /filename=([^;]+)/gi.exec(header);
  if (!matches || !matches[1]) {
    return null;
  }

  const filename = matches[1].trim().replace(/['"]+/g, '');
  const decoded = urlDecode(filename);
  return decoded;
};

/**
 * Extract a filename form headers.
 *
 * if CORS, it requires Access-Control-Expose-Headers
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Expose-Headers
 * @returns The filename, or null
 */
export const getContentDispositionFilename = (headers: AxiosResponseHeaders): string | null => {
  const contentDisposition = headers['content-disposition'];
  if (!contentDisposition) {
    console.warn(
      `Unable to fetch 'content-disposition' header. Have you configured CORS to expose headers via 'Access-Control-Expose-Headers' ?`,
      headers,
    );
    return null;
  }
  return getFilename(contentDisposition);
};

export const startFileDownload = (blob: Blob, filename: string) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  // start download
  a.click();
};

export const downloadFile = (response: AxiosResponse<Blob>): string | null => {
  const filename = getContentDispositionFilename(response.headers);
  if (!filename) {
    console.warn(`Unable to parse filename from 'content-disposition' header`, { response });
    return null;
  }
  startFileDownload(response.data, filename);
  return filename;
};
