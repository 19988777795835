import React, { useState } from 'react';

import { PageTitle } from "common/components/page-title/PageTitle"
import { TableContainer } from "common/components/TableContainer/TableContainer";
import { Button, Form, InputGroup, Stack, Table, Modal, Row, Col } from 'react-bootstrap';
import { SearchOutlined } from '@mui/icons-material';
import useGetOrganizations from "../organizations/hooks/useGetOrganizations";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MoreVert from '@mui/icons-material/MoreVert';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import useGetAllReports from './hooks/useGetAllReports';
import { useForm } from 'react-hook-form';
import { ReportModal } from './components/ReportModal';
import { ReportResponse } from 'common/api/multimap';
import { OrganizationReportModal } from './components/OrganizationReportModal';

export const Reports = () => {

  const [organizationsIsLoading, , organizations] = useGetOrganizations();
  const [filter, setFilter] = useState<string>('');

  const [reportsIsLoading, , reports] = useGetAllReports();

  const [reportResponse, setReportResponse] = useState<ReportResponse | undefined>(undefined);

  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState<boolean>(false);

  const [organizationId, setOrganizationId] = useState<string>("");

  const { t } = useTranslation('admin', { keyPrefix: 'organizations.organizations' });

  return <>
    <PageTitle title="Rapporter">
    </PageTitle>
    <h4>Organisasjoner</h4>
    <div className="overflow-scroll" style={{ maxHeight: '500px' }}>
      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på organisasjon'}
              aria-describedby={'Søk på organisasjon'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">{t('tableHeaders.name')}</th>
              <th scope="col">{t('tableHeaders.organizationNumber')}</th>
              <th scope="col">{t('tableHeaders.status')}</th>
              <th scope="col">Antall brukere</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations
                .filter((x) => x.name?.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => {
                  const textA = a.name ? a.name.toUpperCase() : '';
                  const textB = b.name ? b.name.toUpperCase() : '';
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((row, i) => {
                  // const orgnr = organizationNumberFormatter(row.orgnr);
                  return (
                    <tr key={i}>
                      <td>
                        <span>{row.name}</span>
                      </td>
                      <td>
                        <span>{row.organizationNumber}</span>
                      </td>
                      <td>
                        <span>Aktiv</span>
                      </td>
                      <td>
                        <span>{row.userCount}</span>
                      </td>
                      <td className="d-flex gap-3 flex-wrap justify-content-end align-items-center">
                        <Button variant="link" onClick={() => {
                          if (row.organizationId) {
                            setOrganizationId(row.organizationId);
                            setShowOrganizationModal(true);
                          }
                        }}>
                          <MoreVert />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
    <br></br>
    <br></br>
    <Row className="mb-2">
      <Col>
        <h4>Rapporter</h4></Col>
      <Col className="d-flex justify-content-end align-items-center">
        <Button variant="tertiary" onClick={() => {
          setShowReportModal(true);
          setIsEdit(false);
        }}>
          <AddCircleOutline />
          Opprett ny rapport
        </Button>
      </Col>
    </Row>
    <div className="overflow-scroll" style={{ maxHeight: '500px' }}>
      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på rapport'}
              aria-describedby={'Søk på rapport'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>

        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">Navn</th>
              <th scope="col">Beskrivelse</th>
              <th scope="col">Rapport type</th>
              <th scope="col">Objekttype</th>
              <th scope="col">Pris</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {reports && reports.map((row, i) => (
              <tr key={i}>
                <td>
                  <span>{row.systemName}</span>
                </td>
                <td>
                  <span>{row.systemDescription}</span>
                </td>
                <td>{row.reportTypeId}</td>
                <td>{row.objectTypeName}</td>
                <td>{row.reportPrice}</td>
                <td className="d-flex gap-3 flex-wrap justify-content-end align-items-center">
                  <Button variant="link" onClick={() => {

                    setReportResponse(row);
                    setShowReportModal(true);
                    setIsEdit(true);
                  }
                  }>Endre rapport</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </div>
    <OrganizationReportModal show={showOrganizationModal} onHide={() => setShowOrganizationModal(false)} organizationId={organizationId} />
    <ReportModal show={showReportModal} onHide={() => setShowReportModal(false)} edit={isEdit} response={reportResponse} />
  </>
}