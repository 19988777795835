import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { AuthenticatedUserProvider } from 'common/contexts/AuthenticatedUserContext';
import { RegisterErrorCallback } from 'common/helpers/error-handling/ErrorHandling';
import { notify } from 'common/helpers/toast-notification-helper';
import { useEffect } from 'react';
import { ReactNotifications, Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';

import { CustomNavigationClient } from '../auth/CustomNavigationClient';
import { Footer } from '../common/components/Footer';
import { Navbar } from '../common/components/Navbar';
import Router from '../router/Router';
import { AdminProvider } from './AdminContext';
import './App.scss';
import styles from './App.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: styles.colorPrimary,
    },
    secondary: {
      main: styles.colorSecondary,
    },
    // tertiary: {
    //   main: styles.colorTertiary,
    // },
    error: {
      main: styles.colorError,
    },
    warning: {
      main: styles.colorWarning,
    },
    info: {
      main: styles.colorInfo,
    },
    success: {
      main: styles.colorSuccess,
    },
  },
});

type IProps = {
  pca: PublicClientApplication;
};

function App(props: IProps) {
  const { pca } = props;
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  useEffect(() => {
    RegisterErrorCallback((err) => {
      notify('danger', err);
    });
  }, []);

  return (
    <MsalProvider instance={pca}>
      <MuiThemeProvider theme={theme}>
        <AuthenticatedUserProvider>
          <AdminProvider>
            <div className="vh-100 d-flex flex-column">
              <div>
                <Navbar></Navbar>
              </div>
              <div className="flex-grow-1">
                <ReactNotifications />
                <Router />
              </div>
              <div className="py-4">
              </div>
            </div>
          </AdminProvider>
        </AuthenticatedUserProvider>
      </MuiThemeProvider>
    </MsalProvider>
  );
}

export default App;
